
















































































































































































































































































































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  COMPARISON_OTHER_SKILLS,
  COMPARISON_OTHER_SKILLS_LOADING,
  FETCH_INSTITUTE_PROGRAM,
  GLOBAL_DEGREE_LOADING,
  GLOBAL_DEGREE_SKILLS,
  GLOBAL_INSTITUTES,
  INSTITUTE_STATE_LOADING,
  LOCAL_INSTITUTES,
  NATIONAL_DEGREE_LOADING,
  NATIONAL_DEGREE_SKILLS,
  NATIONAL_GLOBAL_COMPARISON,
  SELECTED_GLOBAL_INSTITUTE,
  SELECTED_NATIONAL_INSTITUTE,
  UPLOAD_GLOBAL_DEGREE,
  UPLOAD_NATIONAL_DEGREE,
  UPSKILL_COURSE_PAYLOAD
} from "@/store/modules/institute/constants";
import DataLoading from "@/components/shared/DataLoading.vue";

import {
  ActionsTabs,
  NationalGlobalComparison
} from "@/interfaces/modules/institute/national_global_comparison";
import SkillsWithGroupsV2 from "@/components/shared/SkillsWithGroupsV2.vue";
import { filter_skills_based_on_group } from "@/utils/skills_group";
import { Institutes } from "@/interfaces/data_objects/institutes";
import {
  NationalGlobalComparisonPayload,
  UpskillCoursesPayload,
  UpskillCoursesPayloadTypes
} from "@/store/modules/institute/interfaces";
import { format_overall_score } from "@/utils/global";
import { ExtractedSkillsWithGroup } from "@/interfaces/responses/extracted_skills_with_group";
import { Programs } from "@/interfaces/data_objects/programs";
import { ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import GoBackHeader from "@/components/shared/GoBackHeader.vue";

export default Vue.extend({
  name: "NationalGlobalComparison",
  components: { GoBackHeader, SkillsWithGroupsV2, DataLoading },
  // route validator to check user selected file for comparison or not
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const national_institute =
        vm.$store.getters[`institute/${SELECTED_NATIONAL_INSTITUTE}`];
      const global_institute =
        vm.$store.getters[`institute/${SELECTED_GLOBAL_INSTITUTE}`];
      if (
        !national_institute.national_institute ||
        !global_institute.global_institute
      ) {
        next("/institute/comparison/national-global");
      } else next();
    });
  },
  data(): NationalGlobalComparison {
    return {
      loading: false,
      active_skill_group: "Leadership and Management",
      extracted_skills: [],
      active_tab: ActionsTabs.NATIONAL_DEGREE_SKILLS,
      first_time: true,
      national_institute_data: {
        institute: null,
        program: null,
        institutes: [],
        selected_institute_programs: []
      },
      global_institute_data: {
        institute: null,
        program: null,
        institutes: [],
        selected_institute_programs: []
      },
      all_actions: [
        {
          name: "Skills from Degree",
          action: ActionsTabs.NATIONAL_DEGREE_SKILLS
        },
        {
          name: "Skills from Degree",
          action: ActionsTabs.GLOBAL_DEGREE_SKILLS
        },
        {
          name: "Matched Skills",
          action: ActionsTabs.MATCHED_SKILLS
        },
        {
          name: "Skills Gap",
          action: ActionsTabs.SKILLS_GAP
        },
        {
          name: "Redundant Skills",
          action: ActionsTabs.REDUNDANT_SKILLS
        }
      ]
    };
  },
  computed: {
    ActionsTabs() {
      return ActionsTabs;
    },
    ...mapGetters("institute", {
      get_institute_loading: INSTITUTE_STATE_LOADING,
      national_degree_loading: NATIONAL_DEGREE_LOADING,
      global_degree_loading: GLOBAL_DEGREE_LOADING,
      national_degree_skills: NATIONAL_DEGREE_SKILLS,
      global_degree_skills: GLOBAL_DEGREE_SKILLS,
      get_national_institute: SELECTED_NATIONAL_INSTITUTE,
      get_global_institute: SELECTED_GLOBAL_INSTITUTE,
      get_local_universities: LOCAL_INSTITUTES,
      get_global_universities: GLOBAL_INSTITUTES,
      get_comparison_other_skills_loading: COMPARISON_OTHER_SKILLS_LOADING,
      get_comparison_other_skills: COMPARISON_OTHER_SKILLS
    })
  },
  watch: {
    async national_degree_loading(new_value: boolean) {
      if (!new_value) {
        // National institute setting
        this.national_institute_data.institute =
          this.get_national_institute.national_institute;
        this.national_institute_data.program =
          this.get_national_institute.national_institute_program;
        // Fetching national institute program
        this.national_institute_data.selected_institute_programs =
          await this.fetch_institute_program(
            this.get_national_institute.national_institute.id
          );
        // Global institute setting
        this.global_institute_data.institute =
          this.get_global_institute.global_institute;
        this.global_institute_data.program =
          this.get_global_institute.global_institute_program;
        // Fetching global institute program
        this.global_institute_data.selected_institute_programs =
          await this.fetch_institute_program(
            this.get_global_institute.global_institute.id
          );
        this.configure_skills();
        if (this.first_time) {
          await this.init();
          this.first_time = false;
        }
      }
    }
  },
  async mounted() {
    this.national_institute_data.institutes = this.get_local_universities;
    this.global_institute_data.institutes = this.get_global_universities;
    if (
      !this.national_degree_loading &&
      this.get_national_institute.national_institute &&
      this.get_global_institute.global_institute
    ) {
      // National institute setting
      this.national_institute_data.institute =
        this.get_national_institute.national_institute;
      this.national_institute_data.program =
        this.get_national_institute.national_institute_program;
      // Fetching national institute program
      this.national_institute_data.selected_institute_programs =
        await this.fetch_institute_program(
          this.get_national_institute.national_institute.id
        );
      // Global institute setting
      this.global_institute_data.institute =
        this.get_global_institute.global_institute;
      this.global_institute_data.program =
        this.get_global_institute.global_institute_program;
      // Fetching global institute program
      this.global_institute_data.selected_institute_programs =
        await this.fetch_institute_program(
          this.get_global_institute.global_institute.id
        );
      this.configure_skills();
    }
  },
  methods: {
    ...mapMutations({
      root_notification: ROOT_NOTIFICATION
    }),
    format_overall_score,
    ...mapActions("institute", {
      fetch_institute_program: FETCH_INSTITUTE_PROGRAM,
      upload_global_degree: UPLOAD_GLOBAL_DEGREE,
      upload_national_degree: UPLOAD_NATIONAL_DEGREE,
      fetch_national_global_comparison: NATIONAL_GLOBAL_COMPARISON
    }),
    ...mapMutations("institute", {
      set_comparison_other_skills_loading: COMPARISON_OTHER_SKILLS_LOADING,
      set_global_degree_loading: GLOBAL_DEGREE_LOADING,
      set_national_degree_loading: NATIONAL_DEGREE_LOADING,
      set_national_institute: SELECTED_NATIONAL_INSTITUTE,
      set_global_institute: SELECTED_GLOBAL_INSTITUTE,
      set_upskill_courses_payload: UPSKILL_COURSE_PAYLOAD
    }),
    update_skill_group(value: string) {
      if (value === this.active_skill_group) return;
      this.active_skill_group = value;
      this.configure_skills();
    },
    configure_skills() {
      // Extract national degree skills
      if (this.active_tab === ActionsTabs.NATIONAL_DEGREE_SKILLS) {
        this.extracted_skills = filter_skills_based_on_group(
          this.national_degree_skills.skills,
          this.active_skill_group
        );
      }
      // Extract global degree skills
      else if (this.active_tab === ActionsTabs.GLOBAL_DEGREE_SKILLS) {
        this.extracted_skills = filter_skills_based_on_group(
          this.global_degree_skills.skills,
          this.active_skill_group
        );
      }
      // Extract matched skills
      else if (this.active_tab === ActionsTabs.MATCHED_SKILLS) {
        this.extracted_skills = filter_skills_based_on_group(
          this.get_comparison_other_skills.matched,
          this.active_skill_group
        );
      }
      // Extract skills gap
      else if (this.active_tab === ActionsTabs.SKILLS_GAP) {
        this.extracted_skills = filter_skills_based_on_group(
          this.get_comparison_other_skills.gap,
          this.active_skill_group
        );
      }
      // Extract matched skills
      else if (this.active_tab === ActionsTabs.REDUNDANT_SKILLS) {
        this.extracted_skills = filter_skills_based_on_group(
          this.get_comparison_other_skills.redundant,
          this.active_skill_group
        );
      }
    },
    /**
     * Function to process selected national institute
     * Fetching institute programs
     * @param value
     */
    async process_selected_institute(value: Institutes | null) {
      // If institute selected => fetch programs
      if (value) {
        this.national_institute_data.selected_institute_programs =
          await this.fetch_institute_program(value.id);
      }
      // Else reset program related data
      else {
        this.national_institute_data.institute =
          this.get_national_institute.national_institute;
        this.national_institute_data.program =
          this.get_national_institute.national_institute_program;
      }
    },
    /**
     * Function to process selected global institute
     * Fetching institute programs
     * @param value
     */
    async process_selected_global_institute(value: Institutes | null) {
      // If institute selected => fetch programs
      if (value) {
        this.global_institute_data.selected_institute_programs =
          await this.fetch_institute_program(value.id);
      }
      // Else reset program related data
      else {
        this.global_institute_data.institute =
          this.get_global_institute.global_institute;
        this.global_institute_data.program =
          this.get_global_institute.global_institute_program;
      }
    },
    update_tab(tab_value: ActionsTabs) {
      this.active_tab = tab_value;
      this.configure_skills();
    },

    async process_selected_national_program(value: Programs | null) {
      if (value) {
        this.set_comparison_other_skills_loading(true);
        this.set_national_degree_loading(true);
        const selected_national_institute = {
          national_institute: this.national_institute_data.institute,
          national_institute_program: value,
          selected_national_degree_content: "",
          selected_national_degree_file: null
        };
        this.set_national_institute(selected_national_institute);
        this.active_tab = ActionsTabs.NATIONAL_DEGREE_SKILLS;
        await this.init_national_degree_skills();
        await this.init_national_global_comparison();
      } else {
        this.national_institute_data.program =
          this.get_national_institute.national_institute_program;
      }
    },
    async process_selected_global_program(value: Programs | null) {
      if (value) {
        this.set_comparison_other_skills_loading(true);
        this.set_global_degree_loading(true);
        const selected_global_institute = {
          global_institute: this.global_institute_data.institute,
          global_institute_program: this.global_institute_data.program,
          selected_global_degree_content: "",
          selected_global_degree_file: null
        };
        this.set_global_institute(selected_global_institute);
        if (this.active_tab === ActionsTabs.GLOBAL_DEGREE_SKILLS)
          this.active_tab = ActionsTabs.NATIONAL_DEGREE_SKILLS;
        this.configure_skills();
        await this.init_global_degree_skills();
        await this.init_national_global_comparison();
      } else {
        this.global_institute_data.program =
          this.get_global_institute.global_institute_program;
      }
    },
    async init() {
      // Extracting global degree skills
      await this.init_global_degree_skills();
      await this.init_national_global_comparison();
      // Extract other skills
    },
    async init_national_degree_skills() {
      // If user selected global degree or paste content
      if (
        this.get_national_institute.selected_national_degree_content ||
        this.get_national_institute.selected_national_degree_file
      ) {
        const _payload = new FormData();
        // Degree file
        const file = this.get_national_institute.selected_national_degree_file;
        // Institute id
        const institute_id =
          this.get_national_institute.national_institute.id.toString();
        _payload.append("program_file", file);
        _payload.append("type", "file");
        _payload.append("ref_institute_id", institute_id);
        _payload.append("local_type", "file");
        await this.upload_national_degree(_payload); // Upload degree & extract skills
      }
      // Uf user select data from dropdown
      else if (
        this.get_national_institute.national_institute &&
        this.get_national_institute.national_institute_program
      ) {
        const _payload = new FormData();
        const institute_id = this.get_national_institute.national_institute.id;
        const program_id =
          this.get_national_institute.national_institute_program.id;
        _payload.append("ref_institute_id", institute_id.toString());
        _payload.append("local_type", "existing");
        _payload.append("ref_program_id", program_id.toString());
        await this.upload_national_degree(_payload); // Upload degree & extract skills
      }
    },
    async init_global_degree_skills() {
      // If user selected global degree or paste content
      if (
        this.get_global_institute.selected_global_degree_content ||
        this.get_global_institute.selected_global_degree_file
      ) {
        const _payload = new FormData();
        if (this.get_global_institute.selected_global_degree_file) {
          // Degree file
          const file = this.get_global_institute.selected_global_degree_file;
          _payload.append("program_file", file);
          _payload.append("type", "file");
        } else if (this.get_global_institute.selected_global_degree_content) {
          const degree_content =
            this.get_global_institute.selected_global_degree_content;
          _payload.append("degree_content", JSON.stringify(degree_content));
          _payload.append("type", "text");
        }
        // Institute id
        const institute_id =
          this.get_global_institute.global_institute.id.toString();
        _payload.append("ref_institute_id", institute_id);
        _payload.append("local_type", "file");
        await this.upload_global_degree(_payload); // Upload degree & extract skills
      }
      // Uf user select data from dropdown
      else if (
        this.get_global_institute.global_institute &&
        this.get_global_institute.global_institute_program
      ) {
        const _payload = new FormData();
        const institute_id = this.get_global_institute.global_institute.id;
        const program_id =
          this.get_global_institute.global_institute_program.id;
        _payload.append("ref_institute_id", institute_id.toString());
        _payload.append("local_type", "existing");
        _payload.append("ref_program_id", program_id.toString());
        await this.upload_global_degree(_payload); // Upload degree & extract skills
      }
    },
    async init_national_global_comparison() {
      const national_institute = this.get_national_institute.national_institute;
      const national_institute_program =
        this.get_national_institute.national_institute_program;
      const global_institute = this.get_global_institute.global_institute;
      const global_institute_program =
        this.get_global_institute.global_institute_program;
      if (
        national_institute &&
        national_institute_program &&
        global_institute &&
        global_institute_program
      ) {
        const payload: NationalGlobalComparisonPayload = {
          ref_institute_id: national_institute.id,
          ref_program_id: national_institute_program.id,
          compare_institute_id: global_institute.id,
          compare_program_id: global_institute_program.id,
          type: "program"
        };
        await this.fetch_national_global_comparison(payload);
      } else if (national_institute && global_institute) {
        const payload: NationalGlobalComparisonPayload = {
          ref_institute_id: national_institute.id,
          compare_institute_id: global_institute.id,
          type: "file"
        };
        if (national_institute_program)
          payload.ref_program_id = national_institute_program.id;
        else {
          payload.ref_skills = this.national_degree_skills.skills.map(
            (skill: ExtractedSkillsWithGroup.Skills) => skill.ref_skill
          );
        }
        if (global_institute_program)
          payload.compare_program_id = global_institute_program.id;
        else {
          payload.compare_skills = this.global_degree_skills.skills.map(
            (skill: ExtractedSkillsWithGroup.Skills) => skill.ref_skill
          );
        }
        await this.fetch_national_global_comparison(payload);
      }
    },
    /**
     * FUnction to set upskill courses request payload and navigate to upskill page
     */
    async upskill_courses() {
      // Fetching gap list
      const skills_gap = this.get_comparison_other_skills.gap;
      const gap_list = skills_gap.map(
        (skill: ExtractedSkillsWithGroup.Skills) => skill.skill
      );
      if (gap_list.length <= 0) {
        this.root_notification("Skill Gap Not Found");
        return;
      }
      // Setting store variable => upskill_courses_payload
      const payload: UpskillCoursesPayload = {
        gap_list,
        type: UpskillCoursesPayloadTypes.COURSE
      };
      // Setting national institute variables
      // checking if national institute/program is selected
      if (
        this.get_national_institute.national_institute &&
        this.get_national_institute.national_institute_program
      ) {
        payload.ref_institute_id =
          this.get_national_institute.national_institute.id;
        payload.ref_program_id =
          this.get_national_institute.national_institute_program.id;
      }
      // Checking if national institute & degree file is selected
      else if (
        this.get_national_institute.national_institute &&
        (this.get_national_institute.selected_national_degree_file ||
          this.get_national_institute.selected_national_degree_content)
      ) {
        payload.ref_institute_id =
          this.get_national_institute.national_institute.id;
        payload.upload_response_id = this.national_degree_skills.info.file_id;
      }

      // Setting global institute variables
      // checking if global institute/program is selected
      if (
        this.get_global_institute.global_institute &&
        this.get_global_institute.global_institute_program
      ) {
        payload.compare_institute_id =
          this.get_global_institute.global_institute.id;
        payload.compare_program_id =
          this.get_global_institute.global_institute_program.id;
      }
      // Checking if national institute & degree file is selected
      else if (
        this.get_global_institute.global_institute &&
        (this.get_global_institute.selected_global_degree_file ||
          this.get_global_institute.selected_global_degree_content)
      ) {
        payload.compare_institute_id =
          this.get_global_institute.global_institute.id;
        payload.compare_upload_response_id =
          this.global_degree_skills.info.file_id;
      }
      this.set_upskill_courses_payload(payload);
      this.active_tab = ActionsTabs.NATIONAL_DEGREE_SKILLS;
      // Navigate to upskill courses page
      await this.$router.push("result/upskill");
    }
  }
});

import { ExtractedSkillsWithGroup } from "@/interfaces/responses/extracted_skills_with_group";
import { Institutes } from "@/interfaces/data_objects/institutes";
import { Programs } from "@/interfaces/data_objects/programs";

export interface NationalGlobalComparison {
  loading: false;
  active_skill_group: string;
  extracted_skills: ExtractedSkillsWithGroup.Skills[];
  active_tab: ActionsTabs;
  all_actions: AllActions[];
  national_institute_data: InstituteData;
  global_institute_data: InstituteData;
  first_time: boolean;
}

export enum ActionsTabs {
  NATIONAL_DEGREE_SKILLS,
  GLOBAL_DEGREE_SKILLS,
  MATCHED_SKILLS,
  SKILLS_GAP,
  REDUNDANT_SKILLS
}

export interface AllActions {
  name: string;
  action: number;
}

interface InstituteData {
  institute: Institutes | null;
  program: Programs | null;
  institutes: Institutes[];
  selected_institute_programs: Programs[];
}
